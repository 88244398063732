export const environment = {
  production: false,
  currency: '',

  // PARAMETROS Mr Hot Dog SV DEV
  firebase: {
    apiKey: "AIzaSyA9W3398H2Bsw-E62Ww-PYI5-d4edOTKUo",
    authDomain: "ianos-app-mr-hot-dog-sv-dev.firebaseapp.com",
    projectId: "ianos-app-mr-hot-dog-sv-dev",
    storageBucket: "ianos-app-mr-hot-dog-sv-dev.appspot.com",
    messagingSenderId: "1084984684691",
    appId: "1:1084984684691:web:cfe4caa9c3dd956d531d66",
    measurementId: "G-52YJEY4BS3"
  },
  googleMapsKey: 'AIzaSyBaKjLt0225z21ingeVSVZnIV-jg8EnDQM',
  urlBase: 'https://us-central1-ianos-app-mr-hot-dog-sv-dev.cloudfunctions.net',
  apikey: 'e9c70110c08-ecad5b389a480-4ed3-9c138c9d',
  urlBaseWompy: 'https://us-central1-newrolling-pro.cloudfunctions.net/Wompi',
  
};
